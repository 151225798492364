<template>

  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <!-- Form -->
    <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-input
            v-model="model.name"
            rules="required"
            label="Nome"
            placeholder="informe o nome do cliente"
            name="name"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-input
            v-model="model.data.cnpj"
            rules="required"
            label="CNPJ"
            mask="###.###.###/####-##"
            placeholder="Ex: 000.000.000/0000-00"
            name="cnpj"
          />

          <i2-form-input
            v-model="model.data.phone"
            label="Telefone"
            mask="(##) # ####-####"
            placeholder="Ex: (00) 0 0000-0000"
            name="phone"
          />
        </b-col>
      </b-row>
      <b-row>
        <i2-divider />
      </b-row>
      <b-row>
        <p>Dados da Empresa</p>
      </b-row>
      <Address
        :address="model.data.address"
        @input="address"
      />
      <b-row />
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <!-- Form Actions -->
          <div class="d-flex mt-2 text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
// import { BFormCheckbox } from 'bootstrap-vue'
import { alphaNum, email, required } from '@validations'
import Ripple from 'vue-ripple-directive'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import I2Divider from '@/components/i2/content/i2Divider.vue'
import Address from '@/components/i2/content/Address.vue'

export default {
  components: {
    Address,
    I2Divider,
    I2FormInput,
    // BFormCheckbox,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      active: false,
      model: {
        name: '',
        uuid: null,
        data: {
          cnpj: '',
          phone: '',
          address: {
            cep: '',
            logradouro: '',
            complemento: '',
            bairro: '',
            localidade: '',
            uf: '',
          },
        },
      },
      blankModel: {
        name: '',
        uuid: null,
        data: {
          cnpj: '',
          phone: '',
          address: {
            cep: '',
            logradouro: '',
            complemento: '',
            bairro: '',
            localidade: '',
            uf: '',
          },
        },
      },
    }
  },
  watch: {
    isActive(data) {
      this.active = data
    },
    company(data) {
      if (!data.data) {
        data.data = {
          cnpj: '',
          phone: '',
          address: {
            cep: '',
            logradouro: '',
            complemento: '',
            bairro: '',
            localidade: '',
            uf: '',
          },
        }
      }

      this.model = data
      this.model.data = {
        phone: data.data.phone,
        cnpj: data.data.cnpj,
        address: data.data.address,
      }

      if (!data.uuid) {
        this.model = { ...this.blankModel }
        this.$refs.refFormObserver.reset()
      }
    },
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          if (this.model.uuid) {
            response = await this.$http.put(`company/${this.model.uuid}`, this.model)
          } else {
            response = await this.$http.post('company', this.model)
          }
          if (response.error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error_message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            return
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Empresa salva com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$emit('onSave', response)
          this.model = { ...this.blankModel }
          this.$refs.refFormObserver.reset()
        }
      })
    },
    address(value) {
      this.model.data.address = value.address
    },
  },
}
</script>
