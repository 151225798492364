<template>
  <b-card-code>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="isActivated = true"
        >
          <FeatherIcon icon="PlusIcon" />
          <span class="text-nowrap">&nbsp;Adicionar</span>
        </b-button>
      </b-col>
    </b-row>

    <i2-side-bar
      :is-active.sync="isActivated"
      window-title="Empresa"
    >
      <company
        :company="current"
        @onSave="onSave()"
      />
    </i2-side-bar>

    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
    >
      <template
        #cell(action)="data"
        style="width: 10%"
      >
        <div class="px-4 text-center">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="setCurrent(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="settings(data.item)">
              <font-awesome-icon :icon="['far', 'building']" />
              <span class="align-middle ml-50">Unidades</span>
            </b-dropdown-item>
            <b-dropdown-item @click="remove(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </Grid>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Grid from '@/components/i2/grid/Grid.vue'
import I2SideBar from '@/components/i2/container/I2SideBar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Company from '@/views/company/Company.vue'

export default {
  components: {
    Company,
    I2SideBar,
    Grid,
    BCardCode,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-1',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-10',
        },
      ],
      items: [],
      current: {},
      isActivated: false,
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.current = {}
      }
    },
  },
  mounted() {
    this.getCompanies()
  },
  methods: {
    async getCompanies() {
      this.items = await this.$http.get('company')
    },
    setCurrent(company) {
      this.current = { ...company }
      this.isActivated = true
    },
    onSave() {
      this.current = {}
      this.isActivated = false
      this.getCompanies()
    },
    remove(company) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme a exclusão do cliente.', {
          title: 'Confirma a exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`company/${company.uuid}`)
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `A empresa ${company.name} foi removida com sucesso.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              await this.getCompanies()
            }
          }
          return true
        })
    },
    settings(company) {
      this.$router.push(`/empresa/${company.uuid}/unidade`)
    },
  },
}
</script>
<style>
th.w-1 {
  width: 50%;
}
th.w-2 {
  width: 20%;
}
th.w-3 {
  width: 10%;
}
th.w-10 {
  width: 20%;
  text-wrap: none;
}
</style>
